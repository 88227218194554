import store from '@/store/index'

export async function loginRequired (to, _from) {
    if (store.state.auth.currentUser === null) {
        await store.dispatch('auth/loadUser')
    }
    return !!(store.state.auth.currentUser && store.state.auth.currentUser.username)
}

export async function applicationPermissionRequired (
    to,
    from,
    permission = 'admins'
) {
    let hasPermission
    const login = await loginRequired(to, from)
    if (login) {
        if (store.state.application.applications.length === 0) {
            await store.dispatch('application/getApplications')
        }
        const application = store
            .getters['application/applicationBySlug'](to.params.application)
        hasPermission = application[permission].find(
            (user) => user === store.state.auth.currentUser.id
        )
        if (!hasPermission) {
            store.commit('alert/ADD_PERMISSION_DENIED')
        }
    }
    return !!hasPermission
}

export async function groupRequired (to, from, groupRequired) {
    let group
    if (loginRequired(to, from)) {
        group = store.getters['auth/findGroup'](groupRequired)
    }

    if (!group) {
        store.commit('alert/ADD_PERMISSION_DENIED')
    }

    return !!group
}

export async function permissionRequired (to, from, permission) {
    let hasPermission
    const login = await loginRequired(to, from)
    if (login) {
        hasPermission = store.getters['auth/findPermission'](permission)
    }

    if (!hasPermission) {
        store.commit('alert/ADD_PERMISSION_DENIED')
    }

    return !!hasPermission
}

export async function permissionsRequired (to, from, permissions) {
    let hasPermissions
    const login = await loginRequired(to, from)
    if (login) {
        hasPermissions = store.getters['auth/checkPermissions'](permissions)
    }

    if (!hasPermissions) {
        store.commit('alert/ADD_PERMISSION_DENIED')
    }

    return hasPermissions
}

export function utilsBeforeEach () {
    store.commit('START_LOADING')
}

export function utilsAfterEach () {
    store.commit('END_LOADING')
}
